import React, { Suspense, useContext, useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import { Routes, Route } from "react-router-dom";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../components/loader/loader";

const Home = React.lazy(() => import("../pages/home"));
const UserList = React.lazy(() => import("../pages/user/list"));
const UserView = React.lazy(() => import("../pages/user/view"));
const UserComments = React.lazy(() => import("../pages/comments/list"));
const UserCommentView = React.lazy(() => import("../pages/comments/moderate"));
const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminCreate = React.lazy(() => import("../pages/admin/add"));
const AdminEdit = React.lazy(() => import("../pages/admin/edit"));
const ChangesPassword = React.lazy(() =>
  import("../pages/settings/changePassword")
);
const Settings = React.lazy(() => import("../pages/settings/settings"));
const ChangesPasswordAdmin = React.lazy(() =>
  import("../pages/admin/adminChangePassword")
);
const CategoryList = React.lazy(() => import("../pages/category/list"));
const CategoryAdd = React.lazy(() => import("../pages/category/add"));

const PublisherList = React.lazy(() => import("../pages/publisher/list"));
const PublisherAdd = React.lazy(() => import("../pages/publisher/add"));

const TagsList = React.lazy(() => import("../pages/tags/list"));
const TagsAdd = React.lazy(() => import("../pages/tags/add"));

const ShortsList = React.lazy(() => import("../pages/shorts/list"));
const ShortsAdd = React.lazy(() => import("../pages/shorts/add"));


const QuizList = React.lazy(() => import("../pages/quiz/list"));
const QuizScorecard = React.lazy(() => import("../pages/quiz/scorecard"));
const QuizAnswerSheet = React.lazy(() => import("../pages/quiz/answerByUser"));
const QuizAdd = React.lazy(() => import("../pages/quiz/add"));
const QuestionList = React.lazy(() => import("../pages/quiz/questionList"));
const QuizCate = React.lazy(() => import("../pages/quiz-category/list"));
const QuizCateAdd = React.lazy(() => import("../pages/quiz-category/add"));
// const QuizQues = React.lazy(() => import("../pages/quiz-questions/list"));
// const QuizQuesAdd = React.lazy(() => import("../pages/quiz-questions/add"));

const CityList = React.lazy(() => import("../pages/city/list"));
const CityAdd = React.lazy(() => import("../pages/city/add"));
const PostList = React.lazy(() => import("../pages/post/list"));
const LiveNewsList = React.lazy(() => import("../pages/post/liveNewList"));
const PostAdd = React.lazy(() => import("../pages/post/addEdit"));
const StoryList = React.lazy(() => import("../pages/story/list"));
const StoryAdd = React.lazy(() => import("../pages/story/addEdit"));
const StoryListAds = React.lazy(() => import("../pages/story-ads/list"));
const StoryAddAds = React.lazy(() => import("../pages/story-ads/addEdit"));
const ArticleSliderAdd = React.lazy(() => import("../pages/articleSlider/addEdit"));
const ArticleSliderAds = React.lazy(() => import("../pages/articleSlider/adsSlider"));
const MetaHome = React.lazy(() => import("../pages/meta/home"));

const ContactUs = React.lazy(() => import("../pages/contact/list"));

const TermsCondition = React.lazy(() => import("../pages/information/terms"));
const PrivacyPolicy = React.lazy(() => import("../pages/information/privacy"));
const AboutUs = React.lazy(() => import("../pages/information/about"));
const AdsList = React.lazy(() => import("../pages/ads/list"));
const AdsAdd = React.lazy(() => import("../pages/ads/add"));
const PollsList = React.lazy(() => import("../pages/polls/list"));
const PollsUserList = React.lazy(() => import("../pages/polls/userList"));
const PollsAdd = React.lazy(() => import("../pages/polls/edit"));
const PollsView = React.lazy(() => import("../pages/polls/view"));

const ElecList = React.lazy(() => import("../pages/election/election/list"));
const ElecAdd = React.lazy(() => import("../pages/election/election/add"));
const ElecCandidateList = React.lazy(() => import("../pages/election/candidate/list"));
const ElecCandidateAdd = React.lazy(() => import("../pages/election/candidate/add"));
const ElecPartyList = React.lazy(() => import("../pages/election/party/list"));
const ElecPartyAdd = React.lazy(() => import("../pages/election/party/add"));
const ElecconstituencyList = React.lazy(() => import("../pages/election/constituency/list"));
const ElecconstituencyAdd = React.lazy(() => import("../pages/election/constituency/add"));
const ElecstatisticsList = React.lazy(() => import("../pages/election/statistics/list"));
const ElecstatisticsAdd = React.lazy(() => import("../pages/election/statistics/add"));
const ElecdistrictList = React.lazy(() => import("../pages/election/district/list"));
const ElecdistrictAdd = React.lazy(() => import("../pages/election/district/add"));
// const GlobalSettings = React.lazy(() => import("../pages/settings/add"));

function Layout() {
  const context = useContext(UserContext);
  const { userInfo } = useContext(UserContext);

  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/v1/admins/token/refresh`,
    })
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
      })
      .catch((error) => {
        // reactLocalStorage.remove("token");
      });
  }
  useEffect(() => {
    let token = reactLocalStorage.get("token");
    const intrVal = setInterval(() => {
      refreshtoken();
    }, 60000);
    if (!token) {
      clearInterval(intrVal);
    }
  }, []);

  return (
    <div
      className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
    >
      <ToastContainer />
      <Header />
      <div className="all-content-wrapper">
        <TopNavbar />
        <Loader />
        <Suspense fallback={"loading"}>
          {/* MODULE_MANAGER */}

          {userInfo.role == "MODULE_MANAGER" ? (
            <Routes>
            
              <Route path="*" element={<NotFound />} />
              
            </Routes>
          ) : userInfo.role == "ACCOUNT_MANAGER" ? ( //Account_Manager
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
            </Routes>
          ) : userInfo.role === "CONTENT_MANAGER" ? ( //Content_Manager
            <Routes>
              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />     
            </Routes>
          ) : (
            <Routes>
              {" "}
              {/* Admin */}
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/admin" element={<AdminList />} />
              <Route path="/user" element={<UserList />} />
              <Route path="/users/add" element={<UserView />} />
              <Route path="/user-view/:id" element={<UserView />} />
              <Route path="/comments" element={<UserComments />} />
              <Route path="/comments-view/:id" element={<UserCommentView />} />
              <Route path="/create-admin" element={<AdminCreate />} />
              <Route path="/change-password" element={<ChangesPassword />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/tags" element={<TagsList />} />
              <Route path="/add-tags" element={<TagsAdd />} />
              <Route path="/shorts" element={<ShortsList />} />
              <Route path="/add-shorts" element={<ShortsAdd />} />
              <Route path="/quiz" element={<QuizList />} />
              <Route path="/quiz/scorecard/:quizId" element={<QuizScorecard />} />
              <Route path="/quiz/answersheet/:userName/:quizId/:userId" element={<QuizAnswerSheet />} />
              <Route path="/add-quiz" element={<QuizAdd />} />
              <Route path="/quiz-category" element={<QuizCate />} />
              <Route path="/add-quiz-category" element={<QuizCateAdd />} />
              {/* <Route path="/quiz-questions" element={<QuizQues />} />
              <Route path="/add-quiz-questions" element={<QuizQuesAdd />} /> */}
              <Route path="/questions-list/:quizId" element={<QuestionList />} />
              <Route path="/questions-list/:quizId/:qsnId" element={<QuestionList />} />
              <Route path="/city" element={<CityList />} />
              <Route path="/add-city" element={<CityAdd />} />
              <Route path="/add-post" element={<PostAdd />} />
              <Route path="/posts" element={<PostList />} />
              <Route path="/add-story" element={<StoryAdd />} />
              <Route path="/story" element={<StoryList />} />
              <Route path="/add-story-ads" element={<StoryAddAds />} />
              <Route path="/story-ads" element={<StoryListAds />} />
              <Route path="/live-news" element={<LiveNewsList />} />
              <Route path="/meta-home" element={<MetaHome />} />
              <Route path="/polls" element={<PollsList />} />
              <Route path="/polls/users/:pollId" element={<PollsUserList />} />
              <Route path="/add-polls" element={<PollsAdd />} />
              <Route path="/edit-polls/:id" element={<PollsAdd />} />
              <Route path="/polls-view/:id" element={<PollsView />} />
              {/* <Route path="/settings" element={<GlobalSettings />} /> */}
              

              <Route
                path="/change-password/:id"
                element={<ChangesPasswordAdmin />}
              />
              <Route path="/publisher" element={<PublisherList />} />
              <Route path="/add-publisher" element={<PublisherAdd />} />

              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />

              <Route path="/articleSlider" element={<ArticleSliderAdd />} />
              {/* <Route path="/articleSliderAds" element={<ArticleSliderAds />} /> */}
              <Route path="/ads/list" element={<AdsList />} />
              <Route path="/ads/add" element={<AdsAdd />} />
              <Route path="/ads/edit/:slug" element={<AdsAdd />} />
              <Route path="/election/party" element={<ElecPartyList />} />
              <Route path="/election/party/create" element={<ElecPartyAdd />} />
              <Route path="/election" element={<ElecList />} />
              <Route path="/election/create" element={<ElecAdd />} />
              <Route path="/election/candidate" element={<ElecCandidateList />} />
              <Route path="/election/candidate/create" element={<ElecCandidateAdd />} />
              <Route path="/election/constituency" element={<ElecconstituencyList />} />
              <Route path="/election/constituency/create" element={<ElecconstituencyAdd />} />
              <Route path="/election/statistics" element={<ElecstatisticsList />} />
              <Route path="/election/statistics/create" element={<ElecstatisticsAdd />} />
              <Route path="/election/district" element={<ElecdistrictList />} />
              <Route path="/election/district/create" element={<ElecdistrictAdd />} />
            </Routes>
          )}
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
